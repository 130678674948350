import { primaryInput } from "detect-it";
import Flickity from "flickity";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import body from "../lib/body";
import { constrain } from "../lib/helpers";
gsap.registerPlugin(ScrollToPlugin);

export default {
    targets: ["slider", "slide", "overlay", "clone"],
    start() {
        this.slider = new Flickity(this.sliderTarget, {
            // contain: true,
            cellAlign: "center",
            pageDots: false,
            prevNextButtons: false,
            resize: true,
            draggable: primaryInput == "touch",
            selectedAttraction: 0.015,
        });

        this.slider.on("scroll", this.onSliderScroll);
        this.slider.on("resize", this.createScales);
        this.slider.on("change", this.onSlideChange);
        this.slider.on("staticClick", this.onStaticClick);

        this.$events.on("artwork:details-toggled", this.onDetails);

        this.overlayTarget.addEventListener(
            "touchmove",
            this.onOverlayTouchMove
        );

        this.$el.addEventListener("touchmove", this.onTouchMove);
        this.$el.addEventListener("touchend", this.onTouchEnd);

        this.onSlideChange();
    },
    onOverlayTouchMove(event) {
        // event.preventDefault();
    },
    onTouchStart(event) {
        event.preventDefault();
        const { clientY } = event.touches[0];
        this.lastY = clientY;
        this.deltaY = 0;
    },
    onTouchMove(event) {
        // event.preventDefault();
        const { clientY } = event.touches[0];
        this.dragged = true;
        this.deltaY = this.lastY - clientY;
        // this.onPan({ deltaY: this.deltaY });
        this.lastY = clientY;
    },
    onTouchEnd() {
        setTimeout(() => {
            this.dragged = false;
        }, 500);
    },
    onDetails(showing) {
        this.showingDetails = showing;
        if (showing) {
            this.sliderTarget.style.pointerEvents = "none";
            this.slideTargets.forEach(
                (slide) => (slide.style.pointerEvents = "none")
            );
        } else {
            this.sliderTarget.style.pointerEvents = "auto";
            this.slideTargets.forEach(
                (slide) => (slide.style.pointerEvents = "auto")
            );
        }
    },
    async zoomIn() {
        this.currentSlide = this.slider.selectedElement;

        gsap.to(this.currentSlide, {
            opacity: 0.5,
        });

        // ScrollSmoother.get().paused(true);
        await this.cloneAndPlaceSlide(this.currentSlide.children[0]);
        await this.enableOverlay();
        this.$events.emit("artwork:zoom-in");
        this.pushOthers(this.currentSlide);

        const ratioLargerThanViewport =
            this.getImageRatio() > this.getViewportRatio();
        if (ratioLargerThanViewport) {
            this.zoomToHeight();
        } else {
            this.zoomToWidth();
        }
    },
    zoomToWidth() {
        this.overlayTarget.style.overflowY = "scroll";
        gsap.to(this.cloneTarget, {
            x: 0,
            y: this.cloneTarget._yOffset,
            width: window.innerWidth,
            ease: "expo.inOut",
            duration: 1.5,
            onComplete: this.onAfterZoomIn,
        });
    },
    zoomToHeight() {
        this.overlayTarget.style.overflowX = "scroll";
        gsap.to(this.cloneTarget, {
            x: this.cloneTarget._xOffset,
            y: 0,
            width: window.innerHeight * this.getImageRatio(),
            height: window.innerHeight,
            ease: "expo.inOut",
            duration: 1.5,
            onComplete: this.onAfterZoomIn,
        });
    },
    getImageRatio() {
        return this.cloneTarget.offsetWidth / this.cloneTarget.offsetHeight;
    },
    getViewportRatio() {
        return window.innerWidth / window.innerHeight;
    },
    onAfterZoomIn() {
        this.transitioning = false;
        if (this.cloneTarget._yOffset == 0) {
            this.panConfig = {
                min: this.cloneTarget._yOffset,
                max: -this.cloneTarget.offsetHeight + window.innerHeight,
            };
            // this.panTimeline = new gsap.timeline({
            //     paused: true,
            // });
            // this.panTimeline.fromTo(
            //     this.cloneTarget,
            //     {
            //         y: this.cloneTarget._yOffset,
            //     },
            //     {
            //         y:
            //             -this.cloneTarget.offsetHeight +
            //             window.innerHeight,
            //     }
            // );

            window.addEventListener("wheel", this.onPan);
        }
    },
    async zoomOut() {
        if (this.transitioning) return;
        this.transitioning = true;
        this.$events.emit("artwork:zoom-out");
        window.removeEventListener("wheel", this.onPan);
        await this.resetSlide();
        this.pullOthers(this.currentSlide);
        await this.returnSlide();
        await this.disableOverlay();
        this.transitioning = false;
        // ScrollSmoother.get()?.paused?.(false);
        // body.unlockScroll();
        this.cloneTarget.remove();
        this.currentSlide = null;
    },
    cloneAndPlaceSlide(img) {
        return new Promise((resolve) => {
            const clone = img.cloneNode();
            clone.src = null;
            clone.srcset = "";

            clone.onload = () => {
                const { top, left } = img.getBoundingClientRect();

                this.overlayTarget.appendChild(clone);
                clone._original = img;
                clone._yOffset =
                    clone.offsetHeight < window.innerHeight
                        ? (window.innerHeight - clone.offsetHeight) * 0.5
                        : 0;
                clone._xOffset =
                    this.getImageRatio() < this.getViewportRatio()
                        ? (window.innerWidth - clone.offsetWidth) * 0.5
                        : 0;
                clone._zoomProps = {
                    x: left,
                    y: top,
                };

                if (this.getImageRatio() > this.getViewportRatio()) {
                    clone._zoomProps.height = img.offsetHeight;
                    clone._zoomProps.maxWidth = "none";
                } else {
                    clone._zoomProps.width = img.offsetWidth;
                }
                gsap.set(clone, clone._zoomProps);

                resolve();
            };
            // clone.style.pointerEvents = "none";
            clone.src = img.getAttribute("data-image-url");
            clone.setAttribute("data-artwork-slider-target", "clone");
            clone.className = "";
        });
    },
    resetSlide() {
        if (this.panTimeline) {
            this.panTimeline.kill();
            this.panTimeline = null;
        }
        this.panConfig = undefined;
        // return new Promise((resolve) => {
        //     this.panTimeline.kill();
        //     gsap.to(this.cloneTarget, {
        //         y: this.cloneTarget._yOffset,
        //         ...this.cloneTarget._zoomProps,
        //         expo: "power3.out",
        //         duration: 1,
        //         onComplete: resolve,
        //     });
        // });
    },
    imageIsOppositeAspect(img) {
        // const landscapeViewport = window.innerWidth > window.innerHeight
        // const landscapeImage = img.offsetWidth > img.offsetHeight
        // const portraitViewport = img.offset
    },
    onStaticClick(event, pointer, cellElement, cellIndex) {
        if (this.dragged) return;

        if (cellElement && cellElement == this.slider.selectedElement) {
            if (!this.transitioning) {
                this.zoomIn();
            }
            this.transitioning = true;
        } else {
            if (cellElement) {
                const cursor = cellElement.getAttribute("data-cursor");
                if (cursor == "leftArrow") this.slider.previous();
                else this.slider.next();
            }
        }
    },
    returnSlide(img) {
        gsap.set(this.currentSlide, {
            opacity: 1,
        });
        return new Promise((resolve) => {
            const props = {
                ...this.cloneTarget._zoomProps,
                width: this.cloneTarget._original.offsetWidth,
                height: this.cloneTarget._original.offsetHeight,
            };
            gsap.to(this.cloneTarget, {
                ...props,
                duration: 1.5,
                ease: "expo.inOut",
                onComplete: () => {
                    resolve();
                },
            });
        });
    },
    onPan({ deltaY }) {
        if (this.panTimeline)
            this.panTimeline.progress(
                this.panTimeline.progress() + deltaY * 0.001
            );

        // if (this.panConfig) {
        //     const delta = deltaY * 0.001;
        //     const value = gsap.getProperty(this.cloneTarget, "y") + delta;
        //     const y = constrain(this.panConfig.min, this.panConfig.max, value);
        //     gsap.to(this.cloneTarget, {
        //         y,
        //         duration: 0.5,
        //         ease: "power2.out",
        //     });
        // }
    },
    pushOthers(slide) {
        const prev = slide.previousSibling?.children?.[0];
        const next = slide.nextSibling?.children?.[0];

        if (prev)
            gsap.to(prev, {
                x: -500,
                ease: "expo.inOut",
                duration: 1.5,
            });

        if (next)
            gsap.to(next, {
                x: 500,
                ease: "expo.inOut",
                duration: 1.5,
            });
    },
    pullOthers(slide) {
        const prev = slide.previousSibling?.children?.[0];
        const next = slide.nextSibling?.children?.[0];

        if (prev)
            gsap.to(prev, {
                x: 0,
                ease: "expo.inOut",
                duration: 1.5,
            });

        if (next)
            gsap.to(next, {
                x: 0,
                ease: "expo.inOut",
                duration: 1.5,
            });
    },
    enableOverlay() {
        this.overlayTarget.style.pointerEvents = "auto";
        // this.overlayTarget.style.height = window.innerHeight + "px";
        return new Promise((resolve) => {
            gsap.to(this.overlayTarget, {
                opacity: 1,
                onComplete: resolve,
            });
        });
    },
    disableOverlay() {
        this.overlayTarget.style.pointerEvents = "none";
        return new Promise((resolve) => {
            gsap.to(this.overlayTarget, {
                opacity: 0,
                onComplete: resolve,
            });
        });
    },
    onSlideChange() {
        let foundSelected = false;

        this.slider.cells.forEach(({ element }) => {
            let cursor;
            if (element == this.slider.selectedElement) {
                foundSelected = true;
                cursor = "plus";
            } else {
                if (foundSelected) cursor = "rightArrow";
                else cursor = "leftArrow";
            }

            element.setAttribute("data-cursor", cursor);
        });
    },
    stop() {
        this.slider.destroy();

        this.overlayTarget.removeEventListener(
            "touchmove",
            this.onOverlayTouchMove
        );

        this.$el.removeEventListener("touchmove", this.onTouchMove);
        this.$el.removeEventListener("touchend", this.onTouchEnd);
    },
};
