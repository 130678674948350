import { primaryInput } from "detect-it";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import body from "../lib/body";
import { currentPageIsOverview, getScrollBarWidth } from "../lib/helpers";

export default {
    targets: [
        "main",
        "menuButton",
        "content",
        "showButton",
        "hideButton",
        "nav",
        "locations",
        "outerContent",
    ],
    start() {
        this.buttonsVisible = false;
        this.$events.on("routing:navigation", this.onNavigation);
        this.$events.on("routing:navigation-after", this.onNavigationAfter);
        this.ease = "expo.inOut";
        this.duration = 1;
        this.observer = ScrollTrigger.observe({
            target: window,
            onUp: ({ event }) => {
                if (event instanceof WheelEvent) {
                    this.onDownScroll();
                }
            },
            wheelSpeed: -1,
            type: "wheel,touch",
            onRelease: ({ deltaY, ...event }) => {
                if (this.isVisible) {
                    if (this.deltaY < 0 || this.draggedYTotal < 0) {
                        this.onDownScroll({ snap: true });
                    } else {
                        gsap.to(this.outerContentTarget, {
                            y: 0,
                            ease: "expo.out",
                            duration: this.duration,
                        });
                        this.pushPage({ snap: true });
                    }
                }
                this.draggedYTotal = 0;
            },
            onDragStart: (event) => {
                this.draggedYTotal = 0;
            },
            onDrag: ({ deltaY }) => {
                this.draggedYTotal += deltaY;
                if (this.isVisible) {
                    const pageContent =
                        document.querySelector("#smooth-wrapper");
                    gsap.set(pageContent, {
                        y: "+=" + deltaY,
                        modifiers: {
                            y: (y) => {
                                const value = Number(y.split("px")[0]);
                                return (
                                    Math.min(
                                        value,
                                        this.outerContentTarget.offsetHeight
                                    ) + "px"
                                );
                            },
                        },
                    });
                    gsap.set(this.outerContentTarget, {
                        y: "+=" + deltaY,
                        modifiers: {
                            y: (y) => {
                                const value = Number(y.split("px")[0]);
                                return Math.min(value, 0) + "px";
                            },
                        },
                    });
                }
            },
        });
        this.updateActiveStates();

        // if (primaryInput == "touch" || !ScrollSmoother.get()) {
        //     window.addEventListener("scroll", this.onScrollUpdate);
        // } else {
        //     this.$events.on("scroll:update", this.onScrollUpdate);
        // }

        this.onScrollUpdate();
        this.onFirstLoad();
    },
    onNavigation(data) {
        setTimeout(() => {
            this.onFirstLoad();
        });
        if (this.isVisible) {
            // this.hideMenu(null, null, true);
            this.onScrollUpdate();
            this.updateActiveStates();
        }
    },
    onNavigationAfter() {
        if (!currentPageIsOverview() && window.location.pathname != "/") {
            body.unlockScroll();
        }

        if (currentPageIsOverview()) {
            this.onScrollUpdate();

            // if (this.getScrollY() <= 0) this.hideButtons();
            // else this.showButtons();
        }

        if (window.location.pathname == "/") {
            this.hideMenu();
            this.hideButtons();
        }
    },
    updateActiveStates() {
        const matchInMenu = this.navigationItemTargets.find(
            (item) => item.getAttribute("href") == window.location.pathname
        );

        if (matchInMenu) {
            this.navigationItemTargets.forEach((item) => {
                if (item == matchInMenu) item.classList.remove("inactive");
                else item.classList.add("inactive");
            });
        } else {
            this.navigationItemTargets.forEach((item) => {
                item.classList.remove("inactive");
            });
        }
    },
    showMenu(onStart) {
        // this.outerContentTarget.style.paddingRight = `${getScrollBarWidth()}px`;
        if (!this.isVisible) {
            this.isVisible = true;
            body.lockScroll();
            const currentOffset =
                this.outerContentTarget.getBoundingClientRect();

            if (currentOffset.top <= 0) {
                gsap.to([this.outerContentTarget], {
                    y: 0,
                    ease: this.ease,
                    duration: this.duration,
                    onStart: () => {
                        onStart?.();
                    },
                });
                gsap.to(this.buttonsTarget, {
                    y: this.outerContentTarget.offsetHeight,
                    ease: this.ease,
                    duration: this.duration,
                });
            }
        }
    },
    hideMenu({ onStart, onComplete, fromNavigation, snap } = {}) {
        if (this.isVisible) {
            this.isVisible = false;
            this.resetPage({ snap });
            gsap.to([this.outerContentTarget], {
                y: -this.outerContentTarget.offsetHeight,
                ease: snap ? "expo.out" : this.ease,
                duration: this.duration,
                onStart: () => onStart?.(),
                onComplete: () => {
                    if (!fromNavigation) body.unlockScroll();
                    onComplete?.();
                },
            });
            gsap.to(this.buttonsTarget, {
                y: 0,
                ease: snap ? "expo.out" : this.ease,
                duration: this.duration,
            });
        }
    },
    updateHeight() {
        // const subtraction = NEGATIVE_TOP_SPACE[window.location.pathname] || 0;
        // this.outerContentTarget.height = `${500 - subtraction}px`;
    },
    pushPage({ snap } = {}) {
        const pageContent = document.querySelector("#smooth-wrapper");
        this.pagePushed = true;
        gsap.to(pageContent, {
            y: this.outerContentTarget.offsetHeight,
            ease: snap ? "expo.out" : this.ease,
            duration: this.duration,
        });
    },
    resetPage({ snap } = {}) {
        const pageContent = document.querySelector("#smooth-wrapper");
        gsap.to(pageContent, {
            y: 0,
            ease: snap ? "expo.out" : this.ease,
            duration: this.duration,
            clearProps: "transform",
        });
    },
    showButtons() {
        if (!this.buttonsVisible) {
            this.buttonsVisible = true;
            gsap.fromTo(
                this.buttonsTarget,
                { opacity: 0 },
                {
                    opacity: 1,
                }
            );
        }
    },
    hideButtons() {
        if (this.buttonsVisible) {
            this.buttonsVisible = false;
            gsap.fromTo(
                this.buttonsTarget,
                { opacity: 1 },
                {
                    opacity: 0,
                }
            );
        }
    },

    getScrollY() {
        return window.scrollY;
    },
    onShowMenuClick() {
        this.showMenu();
        this.pushPage();
        this.showCloseButton();
        // this.setScrollLock(true);
        body.lockScroll();
    },
    onHideMenuClick() {
        this.hideMenu();
        this.resetPage();
        this.showShowButton();
    },
    onFirstLoad() {
        this.updateHeight();
        if (currentPageIsOverview()) {
            if (this.getScrollY() <= 0) {
                body.lockScroll();
                this.pushPage();
                this.showMenu();
                this.hideButtons();
                console.log("should hide buttons");
            } else {
                console.log("should show buttons");
                this.showButtons();
            }
        } else {
            if (window.location.pathname != "/") this.showButtons();
            else this.hideButtons();
        }
    },
    onScrollUpdate() {
        if (currentPageIsOverview()) {
            if (this.getScrollY() <= 0) {
                // body.lockScroll();
                // this.pushPage();
                // this.showMenu();
                // this.hideButtons();
            }
        } else {
            if (window.location.pathname != "/") this.showButtons();
            else this.hideButtons();
        }
    },
    onDownScroll({ snap } = {}) {
        if (window.location.pathname == "/") return;
        this.hideMenu({
            onStart: () => {},
            onComplete: () => {
                this.contentTarget.style.background = "white";
                this.showButtons();
                body.unlockScroll();
            },
            fromNavigation: false,
            snap,
        });
        this.showShowButton();
    },
    showCloseButton() {
        this.showButtonTarget.classList.add("hidden");
        this.hideButtonTarget.classList.remove("hidden");
    },
    showShowButton() {
        this.showButtonTarget.classList.remove("hidden");
        this.hideButtonTarget.classList.add("hidden");
    },
    stop() {},
};

const NEGATIVE_TOP_SPACE = {
    "/exhibitions": 300,
};
