import { clearBodyLocks, lock, unlock } from "tua-body-scroll-lock";
import { getScrollBarWidth } from "./helpers";
import gsap from "gsap";

export default {
    lockScroll: function (enabledScrollContainer) {
        lock([enabledScrollContainer]);
    },
    unlockScroll: function () {
        clearBodyLocks();
    },
};
