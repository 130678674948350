import barba from "@barba/core";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import collectionToArtwork from "./transitions/collection-to-artwork";
import SprinkleApp from "./sprinkle/app";
import Collection from "./components/Collection";
import defaultTransition from "./transitions/default";
import exhibitionsToExhibition from "./transitions/exhibitions-to-exhibition";
import HorizontalGallery from "./components/HorizontalGallery";
import CollectionViewToggle from "./components/CollectionViewToggle";
import ExhibitionText from "./components/ExhibitionText";
import ImageCloser from "./components/ImageCloser";
import ImageZoom from "./components/ImageZoom";
import CurrentExhibitionsSlider from "./components/CurrentExhibitionsSlider";
import StandardGallery from "./components/StandardGallery";
import ArtworkSlider from "./components/ArtworkSlider";
import Artwork from "./components/Artwork";
import PageSlider from "./components/PageSlider";
import Header from "./components/Header";
import Cursor from "./components/Cursor";
import Home from "./components/Home";
import TeamMember from "./components/TeamMember";
import Newsletter from "./components/Newsletter";
import LoanHistory from "./components/LoanHistory";
import Exhibition from "./components/Exhibition";
import CollectionFilters from "./components/CollectionFilters";
import Annotation from "./components/Annotation";
import ExhibitionMenu from "./components/ExhibitionMenu";
import ExhibitionItem from "./components/ExhibitionItem";
import ArtworkItem from "./components/ArtworkItem";
import TeamMemberItem from "./components/BigListItem";
import BigListItem from "./components/BigListItem";
import ExhibitionArchive from "./components/ExhibitionArchive";
import { primaryInput } from "detect-it";
import body from "./lib/body";
import PageHeader from "./components/PageHeader";

gsap.registerPlugin(ScrollTrigger);

export default class App {
    constructor() {
        this.startSprinkles();
        this.sprinkles.emit("scroll:smooth-init");
        this.setupBarbaHooks();
        this.startBarba();

        this.setCSSVars();

        this.enter();
        this.detectDevice();
    }

    enter() {
        gsap.fromTo(
            document.querySelector("#smooth-wrapper"),
            { opacity: 0 },
            { opacity: 1, duration: 2, delay: 0.5 }
        );
    }

    detectDevice() {
        if (primaryInput == "touch") document.body.classList.add("is-touch");
    }

    startSprinkles() {
        this.sprinkles = new SprinkleApp({
            debug: true,
            components: {
                collection: Collection,
                exhibition: Exhibition,
                "horizontal-gallery": HorizontalGallery,
                "collection-view-toggle": CollectionViewToggle,
                "exhibition-text": ExhibitionText,
                "image-closer": ImageCloser,
                "image-zoom": ImageZoom,
                "current-exhibitions": CurrentExhibitionsSlider,
                "standard-gallery": StandardGallery,
                "artwork-slider": ArtworkSlider,
                artwork: Artwork,
                "page-slider": PageSlider,
                header: Header,
                "page-header": PageHeader,
                cursor: Cursor,
                home: Home,
                "team-member": TeamMember,
                newsletter: Newsletter,
                "loan-history": LoanHistory,
                "collection-filters": CollectionFilters,
                annotation: Annotation,
                // "exhibition-menu": ExhibitionMenu,
                "exhibition-item": ExhibitionItem,
                "artwork-item": ArtworkItem,
                "big-list-item": BigListItem,
                "exhibition-archive": ExhibitionArchive,
            },
        });
    }

    setupBarbaHooks() {
        barba.hooks.beforeLeave((data) => {
            // this.smoother?.paused?.(true);
            document.body.style.pointerEvents = "none";
            body.lockScroll();
            // this.sprinkles.destroy();
            this.sprinkles.emit("routing:navigation-before", data);
        });

        barba.hooks.afterLeave((data) => {
            this.sprinkles.emit("routing:navigation", data);
        });

        barba.hooks.beforeEnter(() => {
            if (this.smoother) {
                this.smoother?.scrollTo?.(0, false);
            } else {
                window.scrollTo(0, 0);
            }
        });

        barba.hooks.afterEnter((data) => {
            document.body.style.pointerEvents = "auto";
            if (this.hasLaunched) {
                ScrollTrigger.getAll().forEach((s) => s.kill());
            }

            const wrapper =
                data.next.container.querySelector("#smooth-wrapper");
            const content =
                data.next.container.querySelector("#smooth-content");

            if (this.hasLaunched) {
                // this.setSmoother({ wrapper, content });
            }

            this.sprinkles.emit("routing:navigation-after", data);
            // setTimeout(() => {
            //     body.unlockScroll();
            // }, 500);
            if (!this.hasLaunched) this.hasLaunched = true;
        });

        barba.hooks.after(() => {
            this.sprinkles.boot();
            ScrollTrigger.refresh();
        });
    }

    startBarba() {
        barba.init({
            timeout: 5000,
            transitions: [
                defaultTransition,
                collectionToArtwork,
                exhibitionsToExhibition,
                // publicationsToPublication (2nd)
                // artistToProfile (2nd)
            ],
            prefetchIgnore: true,
            prevent: ({ el, href }) => {
                if (
                    el.classList.contains("exhibition-slide-link") &&
                    !el.closest("is-selected")
                )
                    return true;
            },
        });
    }

    setCSSVars() {
        if (primaryInput == "touch") {
            document.body.classList.add("is-touch");
        }
        document.body.style.setProperty(
            "--window-height",
            `${window.innerHeight}px`
        );
        window.addEventListener("resize", () => {
            document.body.style.setProperty(
                "--window-height",
                `${window.innerHeight}px`
            );
        });
    }
}
